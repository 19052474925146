import axios from '@/common/http';
import { IntegrationSystem } from '@/types';

class IntegrationService {
  private service: typeof axios = axios;

  private baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/api/v1/integrations`;

  public getIntegrations() {
    return this.service.get(this.baseUrl);
  }

  public getIntegrationsCRM() {
    return this.service.get(`${this.baseUrl}/purpose/CRM`);
  }

  public createIntegration(payload: IntegrationSystem) {
    return this.service.post(this.baseUrl, payload);
  }

  public updateIntegration(id: any, payload: IntegrationSystem) {
    return this.service.put(`${this.baseUrl}/${id}`, payload);
  }

  public deleteIntegration(id: any) {
    return this.service.delete(`${this.baseUrl}/${id}`);
  }
}

export default new IntegrationService();
