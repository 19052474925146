var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid" }, [
    _vm._m(0),
    _c("div", { staticClass: "login-container" }, [
      _c(
        "div",
        { staticClass: "i18n" },
        [_c("SupportInternationalization")],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "content-wrap" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            {
              staticClass:
                "welcome-content d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                [
                  _c("h1", { staticClass: "size22 leading-8" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("onboarding.start.hello")) + " "
                    ),
                    _c("span", { staticClass: "username" }, [
                      _vm._v(" " + _vm._s(_vm.user.name) + ", "),
                    ]),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.$t("onboarding.start.welcome")) + " "
                    ),
                  ]),
                  _c("p", { staticClass: "mb-9 mt-8 leading-7 text-grey" }, [
                    _vm._v(" " + _vm._s(_vm.$t("onboarding.start.text")) + " "),
                  ]),
                  _c(
                    "Button",
                    {
                      staticClass: "raised h-50 px-20 text-base",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/onboarding")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("onboarding.start.buttonText"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "form-blur" }),
          _c("div", { staticClass: "bg" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "image-container" }, [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
          alt: "logomarca roit bank",
        },
      }),
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: require("@/common/assets/images/bg-web.jpg"),
          alt: "mulher setada a mesa de frente a laptop",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "logo-on-mobile-wrap" }, [
      _c("div", { staticClass: "entry-logo" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("@/common/assets/images/logo-roit-solutions-white.svg"),
            alt: "logomarca roit bank",
          },
        }),
      ]),
      _c("div", { staticClass: "bg-mobile" }, [
        _c("img", {
          attrs: {
            src: require("@/common/assets/images/bg-mobile.jpg"),
            alt: "mulher setada a mesa de frente a laptop",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }